<template>
<div class="ShowMoreCont d-flex justify-center align-center">
	<v-btn
		@click="SeeMore()"
		class="loadMoreButton"
		rounded outlined
		>
			See more accounts
	</v-btn>
</div>
</template>
<script>
import { mapActions} from "vuex";
export default{
	name:'ShowMoreButton',
	data(){
		return {
			pagination:{
				limit : 10,
				offset: 0,
			},
		}
	},
	methods:{
		...mapActions([
			"getSubscriptionList",
		]),
		//MOSTRAR MAS
		SeeMore(){
			const limit = this.$store.state.subscription.table.limit;
			const offset = this.$store.state.subscription.table.offset;
			this.pagination.limit = limit + 10;
			this.pagination.offset = (offset == 0 ? offset + 0: offset + 10);
			this.getSubscriptionList(this.pagination);	
		}
	}
}
</script>
<style lang="less" scoped>
.ShowMoreCont{
	width: 100%;
	height: 60px;
	.loadMoreButton{
		width: 50%;
		height: 40px!important;
	}
}

@media(max-width: 600px)
{
.ShowMoreCont{
	.loadMoreButton{
		width: 90%;
	}
}
}
</style>